#payment-history {
  padding-top: 80px;
  padding-bottom: 300px;

  .alert-header {
    @media (max-width: 992px) {
      padding-top: 0 !important;

      .title-block {
        padding-right: 25px;
        padding-bottom: 0;
      }
    }
  }

  #pagination {
    @media (max-width: 992px) {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      padding-right: 0;
    }

    @media (max-width: 420px) {
      flex-direction: row-reverse;
    }

    .results {
      margin-left: 5px;

      .text {
        @media (max-width: 420px) {
          display: none;
        }
      }

      @media (max-width: 420px) {
        .arrows {
          margin-right: -10px;
        }
      }
    }
    .pagination-select {
      margin: 0;
      #rowsPerPage {
        padding-right: 0;
        border: none !important;
      }
    }
  }

  .payment-tabs {
    .menuTab {
      @media (max-width: 576px) {
        padding: 5px 10px;
      }
    }
  }

  .container-fluid.app-container {
    padding-top: 20px;
  }

  .div-container-content {
    padding: 0;
  }

  .payment-history-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .payment-method-box {
      display: flex;
      flex-direction: row;
      gap: 40px;
      padding: 30px;
      align-items: center;
      border-radius: 10px;
      border: 0.6px solid #ebeff6;
      background: #fff;
      box-shadow: 0px 2px 8px 0px rgba(25, 33, 61, 0.04), 0px 1px 3px 0px rgba(25, 33, 61, 0.05);
      cursor: unset;
      position: relative;

      .payment-method-arrow-icon {
        display: none;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 768px) {
          display: block;
        }
      }

      @media (max-width: 768px) {
        cursor: pointer;
        padding: 15px;
      }

      .payment-method-box-item {
        display: flex;
        flex-direction: row;
        gap: 40px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 0;
          margin-right: 30px;
        }

        @media (max-width: 381px) {
          margin-right: 15px;
        }

        .payment-method-item {
          display: flex;
          flex-direction: column;
          color: #1d1d1b;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;

          @media (max-width: 768px) {
            font-size: 10px;
          }

          span {
            color: #3d3d3d;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.14px;

            &.active {
              color: #00bd00;
            }

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }

      .payment-method-button {
        border-radius: 5px;
        border: 1px solid #000;
        display: flex;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;
        background: #fff;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  h1 {
    margin-bottom: 3rem;
    font-size: 2.5rem;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    @font-face {
      font-family: Roboto;
      font-style: normal;
    }
  }

  .payment-tabs {
    margin-bottom: 10px;
  }

  .current-period-title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    .current-period-title {
      margin: 0;
      color: #1d1d1b;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 28px; /* 127.273% */
    }

    .more-info {
      @media (max-width: 992px) {
        color: #00bd00;
      }
    }

    .info {
      margin-left: 10px;
      &:hover {
        color: #00bd00;
      }
    }
  }

  .info {
    vertical-align: middle;
  }

  .pdf-invoice {
    cursor: pointer;
  }

  .status {
    text-transform: uppercase;

    &.refused {
      color: $cr-red-error;
    }
  }

  .payments-list {
    margin-bottom: 2rem;
  }

  .payment-history-table {
    .payment-table {
      position: relative;

      .table .tr {
        height: 60px;
        min-height: 60px;
        max-height: 60px;

        .tr-container {
          display: flex;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-content: space-between;

          @media (max-width: 992px) {
            align-items: stretch;
          }

          .td {
            @media (max-width: 992px) {
              width: fit-content !important;

              .amount-box {
                margin-top: 0 !important;
                padding-top: 1px !important;
              }
            }
          }
        }
        @media (max-width: 992px) {
          height: unset;
          min-height: unset;
          max-height: unset;
          gap: 20px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #8c8c8c;
        }
      }

      .table {
        display: flex;
        flex-direction: column;
        .thead {
          border-bottom: 0;
          padding: 10px 0 0 0;
          .header-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0 30px 10px 30px;

            @media (max-width: 992px) {
              margin: 0 18px 10px 18px;
            }
            h2 {
              color: #3d3d3d;
              font-size: 21px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              letter-spacing: -0.21px;

              @media (max-width: 420px) {
                max-width: 65px;
                margin-top: 0;
              }

              .number-payments {
                color: #3d3d3d;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .minimum-payout-box {
              display: flex;
              flex-direction: column;
              text-align: right;
              .minimum-payout-title {
                color: #00bd00;
                font-size: 21px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: -0.21px;

                @media (max-width: 992px) {
                  font-size: 16px;
                }
              }

              .minimum-payout-not-reached-title {
                color: #3d3d3d;
                font-size: 21px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: -0.21px;
                @media (max-width: 992px) {
                  font-size: 16px;
                }
              }

              .minimum-payout-numbers {
                color: #3d3d3d;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                @media (max-width: 992px) {
                  display: flex;
                  justify-content: flex-end;
                }
              }
            }
          }
          .tr {
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            height: fit-content;
            min-height: fit-content;
            max-height: fit-content;
            display: flex;
            margin: 10px 30px;
            padding: 4px 50px 4px 53px;
            background-color: #333;

            @media (max-width: 992px) {
              padding: 4px 15px;
              margin: 10px 18px;
            }

            .th {
              width: fit-content;
              background-color: transparent;
              padding: 0;
              border-radius: 0;

              .label {
                color: #fff;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
              }
            }
          }
        }

        .tbody {
          display: flex;
          flex-flow: column;
          .payment-row {
            &.payment-history-row {
              .has-invoices {
                &:hover {
                  background-color: #f2f2f2;
                }

                .td {
                  .payment-box {
                    @media (max-width: 992px) {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              @media (max-width: 992px) {
                margin-left: 18px;
                margin-right: 18px;
              }

              .payment {
                @media (max-width: 992px) {
                  margin-left: 0;
                  margin-right: 0;
                }

                .payment-date {
                  &.mobile {
                    display: none;
                    @media (max-width: 992px) {
                      display: flex;
                    }
                  }
                  @media (max-width: 992px) {
                    flex-direction: column-reverse;
                    gap: 5px;
                    display: none;
                  }
                }
              }

              .invoices {
                @media (max-width: 992px) {
                  margin-left: 0;
                  margin-right: 0;

                  .tr {
                    margin-left: 0;
                    margin-right: 0;
                  }
                }

                .payment-box {
                  @media (max-width: 992px) {
                    margin-bottom: 0;
                  }
                  .icons-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 992px) {
                      flex-direction: column;
                      gap: 5px;
                      svg {
                        width: 12px;
                        height: 16.364px;
                      }
                    }
                  }
                }

                .amount-box {
                  @media (max-width: 992px) {
                    display: flex;
                    flex-direction: row !important;
                    align-items: flex-end;
                    gap: 10px !important;
                    padding-top: 1px;
                  }
                }
              }
            }
            &:not(:last-child) {
              border-bottom: 1px solid #8c8c8c;
            }
            .tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 4px 80px 4px 83px;
              &.payment {
                &.no-border {
                  border: none;
                }
              }

              @media (max-width: 992px) {
                padding: 17px 15px;
                margin: 0 18px;
              }
              .td {
                width: 100%;
                padding: 0;

                @media (max-width: 992px) {
                  &:first-child {
                    min-width: 133px;
                  }
                }

                &:nth-child(2) {
                  display: flex;
                  justify-content: center;
                }

                &:last-child {
                  display: flex;
                  justify-content: flex-end;
                }

                .payment-box {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 30px;

                  .invoice-icon {
                    &.desktop {
                      display: flex;

                      @media (max-width: 992px) {
                        display: none;
                      }
                    }

                    &.mobile {
                      display: none;

                      @media (max-width: 992px) {
                        display: flex;
                      }
                    }
                  }

                  @media (max-width: 992px) {
                    gap: 10px;
                    margin-bottom: 10px;

                    svg {
                      width: 19px;
                      height: 20.364px;
                    }
                  }
                  .payment-info {
                    display: flex;
                    flex-direction: column;

                    @media (max-width: 992px) {
                      flex-direction: row;
                    }

                    .payment-date {
                      .date {
                        @media (max-width: 992px) {
                          display: flex;
                          flex-direction: row;
                          gap: 10px;
                          align-items: center;
                        }
                      }

                      @media (max-width: 992px) {
                        flex-direction: column;
                        gap: 5px;
                      }

                      .tags-container {
                        display: flex;
                        flex-direction: row;

                        .tooltip-wrapper {
                          user-select: none;
                        }
                      }
                    }
                  }

                  .details-box {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    .details-button {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      color: #00bd00;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: -0.15px;
                      text-transform: uppercase;
                      @media (max-width: 992px) {
                        display: none;
                      }
                    }
                  }
                }

                .amount-box {
                  display: flex;
                  flex-flow: row;
                  gap: 20px;
                  color: #3d3d3d;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  align-items: center;
                  .arrow {
                    &.rotate {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }

            .invoices {
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              .tr {
                border-bottom: 1px solid #8c8c8c;
              }

              .td {
                width: 100%;
                padding: 0;

                &:nth-child(2) {
                  display: flex;
                  justify-content: center;
                }

                &:last-child {
                  display: flex;
                  justify-content: flex-end;
                }

                .payment-box {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 30px;
                  margin-left: 55px;
                  color: #3d3d3d;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;

                  @media (max-width: 992px) {
                    gap: 10px;
                    margin-left: 0;
                    width: fit-content;
                  }
                  .payment-info {
                    display: flex;
                    flex-direction: column;
                  }
                }

                .amount-box {
                  display: flex;
                  flex-flow: row;
                  gap: 20px;

                  @media (max-width: 992px) {
                    flex-direction: column;
                    gap: 5px;
                  }

                  .details-box {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    @media (max-width: 992px) {
                      gap: 5px;
                    }
                    .details-button {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      color: #00bd00;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: -0.15px;
                      text-transform: uppercase !important;

                      @media (max-width: 992px) {
                        display: none;
                      }
                    }
                  }
                }
              }

              .paid-status {
                display: flex;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 5px;
                border: 1px solid #9fd89f;
                background: rgba(0, 189, 0, 0.15);
                max-width: 53px;
                color: #0e700e;
                text-align: center;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 15px */
                letter-spacing: -0.1px;

                @media (max-width: 420px) {
                  padding: 4px;
                }
              }
            }

            &.current-period,
            &.next-payment {
              .carried-over {
                .payment-box {
                  margin-left: 0;
                }
              }

              .invoices {
                .payment-box {
                  margin-left: 0;
                }

                @media (max-width: 992px) {
                  .tr {
                    gap: 0;
                    .td {
                      &:first-child {
                        width: fit-content !important;
                      }
                      &:last-child {
                        width: fit-content !important;
                        flex-direction: column;
                        .amount-box {
                          align-items: flex-end;
                        }
                      }
                    }
                  }
                }
              }
              .tr {
                &:last-child {
                  border-bottom: none;
                }
              }

              .payment-date {
                display: flex;
                flex-direction: row;
                gap: 20px;
                color: #3d3d3d;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                .current-tag {
                  display: flex;
                  padding: 4px 10px 3px 10px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  border-radius: 5px;
                  border: 1px solid #00bd00;
                  background: rgba(0, 189, 0, 0.15);
                  width: fit-content;
                  color: #242424;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;

                  @media (max-width: 992px) {
                    padding: 4px 10px 3px 10px;
                  }
                }
              }

              .current-period-specification {
                color: #00bd00;
                font-size: 10px;
                font-style: italic;
                font-weight: 500;
                line-height: normal;
                margin: 5px 0;

                &.desktop {
                  display: flex;
                  @media (max-width: 992px) {
                    display: none;
                  }
                }

                &.mobile {
                  display: none;
                  @media (max-width: 992px) {
                    display: flex;
                  }
                }
              }

              .active-status {
                display: flex;
                flex-direction: row;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: #fff;
                text-align: center;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 15px */
                letter-spacing: -0.1px;
                border-radius: 5px;
                background: #00bd00;

                &.mobile {
                  display: none;

                  @media (max-width: 992px) {
                    display: flex;
                  }
                }
                &.desktop {
                  @media (max-width: 992px) {
                    display: none;
                  }
                }

                @media (max-width: 992px) {
                  width: 69px;
                  margin-left: 10px;
                }

                .svg {
                  width: 12px;
                  height: 12px;
                }
              }

              .pending-status {
                display: flex;
                height: 23px;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 5px;
                background: #e9f7ff;
                color: #537be9;
                text-align: center;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 15px */
                letter-spacing: -0.1px;
                max-width: 92px;
                &.desktop {
                  @media (max-width: 992px) {
                    display: none;
                  }
                }

                &.mobile {
                  display: none;
                  @media (max-width: 992px) {
                    margin-left: 0;
                    display: flex;
                  }
                }

                @media (max-width: 576px) {
                  margin-left: 10px;
                }
              }
            }

            &.next-payment {
              .invoices {
                .tr {
                  .td {
                    @media (max-width: 992px) {
                      max-width: unset;

                      &:first-child {
                        width: 100% !important;
                      }

                      &.due-date {
                        width: 100% !important;
                        max-width: 69px;
                      }

                      &:last-child {
                        width: 100% !important;
                      }
                    }
                    @media (max-width: 400px) {
                      &:first-child {
                        min-width: unset;
                        max-width: 98px;
                      }

                      &.due-date {
                        @media (max-width: 400px) {
                          margin-left: 5px;
                        }
                      }

                      &:last-child {
                        @media (max-width: 400px) {
                          margin-left: auto;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.current-period {
              .payment {
                @media (max-width: 992px) {
                  margin: 0 18px;
                  gap: 0;
                  flex-direction: column;
                }

                .invoices {
                  .tr {
                    &:last-child {
                      width: fit-content !important;
                      flex-direction: column;
                      justify-content: flex-end;
                      align-items: flex-end;
                    }

                    .td {
                      .amount-box {
                        @media (max-width: 992px) {
                          margin-top: -4px;
                          justify-content: flex-start;
                          flex-direction: column;
                        }
                      }
                    }
                  }
                }
              }

              .tr {
                .td {
                  .amount-box {
                    @media (max-width: 992px) {
                      margin-top: -4px;
                      justify-content: flex-start;
                      flex-direction: column;
                    }
                  }
                }
              }
            }

            &.payment-history-row {
              .tr {
                @media (max-width: 992px) {
                  gap: 0;
                }
                .td {
                  .amount-box {
                    @media (max-width: 992px) {
                      gap: 5px;
                    }
                  }

                  .payment-box {
                    .payment-info {
                      @media (max-width: 992px) {
                        flex-direction: column !important;
                      }
                    }
                  }
                }
              }

              .invoices {
                .tr {
                  .td {
                    &:first-child {
                      @media (max-width: 420px) {
                        min-width: 120px;
                      }
                    }
                  }
                }
              }
            }

            .next-payments-empty-state {
              display: flex;
              padding: 30px 0px;
              flex-direction: column;
              align-items: center;
              gap: 20px;
              align-self: stretch;

              p {
                color: #1d1d1b;
                text-align: center;
                font-feature-settings: 'liga' off, 'clig' off;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */
                letter-spacing: 0.48px;
              }

              .btn-box {
                display: flex;
                flex-direction: row;
              }

              .current-btn,
              .history-btn {
                cursor: pointer;
                display: flex;
                padding: 10px 30px;
                justify-content: center;
                align-items: center;
                gap: 5px;
                color: #00bd00;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
              }

              .history-btn {
                svg {
                  transform: rotate(180deg);
                }
              }

              @media (max-width: 992px) {
                margin: 0 18px;
              }
            }
          }
        }
      }
    }
  }

  .next-payment-box {
    display: flex;
    padding: 30px 44px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 61, 0.35);
    background: #f8f8f8;
    gap: 10px;

    @media (max-width: 992px) {
      padding: 20px 15px;
    }

    .next-payment-amount {
      color: #1d1d1b;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 28px;

      @media (max-width: 992px) {
        color: #1d1d1b;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
      }
    }

    .next-payment-date {
      color: #1d1d1b;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 13px;
        text-transform: unset;
      }
    }
    .next-payment-warning-box {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: rgba(0, 189, 0, 0.15);
      width: fit-content;
      color: #3d3d3d;
      font-size: 10px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }

    .how-to-link {
      color: #00bd00;
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  .past-payment-button {
    cursor: pointer;
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    float: right;
    margin: 40px 0px;
    background-color: transparent;

    @media (max-width: 992px) {
      width: 100%;
      float: none;
    }
  }

  .payment-infos-container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }

    .temporal-history {
      display: flex;
      flex-direction: row;

      :last-child {
        border-left: 1px solid #bbb;
      }

      .payment-date-history {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 6px;
        width: 12rem;

        @media screen and (min-width: 992px) {
          padding-left: 3rem;
          margin-left: 3rem;
          border-left: 1px solid #bbb;
        }

        :first-child {
          color: $cr-green;
          font-size: 14px;
          text-align: center;
        }

        .amount {
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;
        }

        :nth-child(2) {
          width: 200px;
        }

        :last-child {
          border: none;
          text-align: center;
        }
      }
    }

    .to-be-completed {
      display: flex;
      .payment-date-history {
        padding-left: 0;
      }

      .widget {
        max-width: 450px;
        border-left: 1px solid #bbb;
        padding-left: 2rem;
        h3 {
          color: $cr-green;
          margin-top: 5px;
        }
        .notice {
          color: #333;
        }
      }
    }
  }
}

.current-period-tooltip {
  min-width: 200px;
  max-width: 600px;
  width: unset;
}

@include responsive('tablet') {
  #payment-history {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #fff;
  }
}

@include responsive('phone to tablet') {
  #payment-history {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #fff;
  }
}
